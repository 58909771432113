.question-tooltip {
    position: relative;
    display: inline-block;
    font-size: 1rem;
    transform: translateY(-2px);
}

.question-tooltip .question-tooltiptext {
    visibility: hidden;
    min-width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 0.5rem;
    border-radius: 6px;
    position: absolute;
    z-index: 10;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.question-tooltip .question-tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.question-tooltip:hover .question-tooltiptext {
    visibility: visible;
    opacity: 1;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.m-0 {
    margin: 0px;
}

/* progress bar styling ***/

.progress-list {
    margin: 0px;
}

.progress-container li {
    background-color: #eaeaea;
    color: #000;
    width: 30px;
    height: 28.5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    position: relative;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 2rem;
}

.progress-container li.filled {
    border: 1px solid #ffba1c;
    background-color: #ffba1c;
}

.progress-container li.active {
    border: 4px solid #ffba1c;
}

.progress-container li:not(:last-child)::after {
    content: "";
    width: 3rem;
    height: 3px;
    background-color: #eaeaea;
    position: absolute;
    top: 50%;
    right: -3.8rem;
}

.progress-container li.filled::after {
    background-color: #ffba1c;
}

.progress-container li:not(:last-child) {
    margin-right: 4rem;
}

.progress-container {
    display: none;
    width: 100%;
}

.progress-container.active {
    display: flex;
    align-items: center;
    justify-content: center;
}

/****************************** Progress bar styling *******************/


/* custome select styling **/
select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #eaeaea;
    background-image: none;
    color: #000;
    cursor: pointer;
    height: 100%;
    border-radius: 0px;
}

.select {
    position: relative;
    overflow: hidden;
    border-radius: 0em;
    height: 40px;
}

.select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 15px;
    padding: 0 1em;
    background: #555;
    cursor: pointer;
    height: 100%;
    pointer-events: none;
    transition: .25s all ease;
    color: #FFB81C;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
}

select.form-control::-ms-expand {
    display: none;
}


.select:hover::after {
    color: #FFB81C;
}


/* end of custom select styling*/


.question-container .btn-yellow-darktxt, .question-container .btn-grey {
    flex-basis: 120px;
}

.qualification-button {
    flex-basis: 40px;
}

.p-0 {
    padding: 0px;
}

.error-msg {
    color: $cherryAlertRed;
    font-size: 1.3rem;
    margin-top: -0.5em;
    display: none;
}

.dropdown-tooltip {
    display: none;
    background-color: #d0d0ce;
    color: #000;
    margin-bottom: 1rem;
    padding: 1rem;
    text-align: left;
}


.main-page-heading {
    font-size: 3rem;
    line-height: 3rem;
}

.action-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    padding: 2rem;
}

.action-container, .main-container, .alert-block.question-block.active, .overarching-button-container no-center, .user-progress-block.question-block.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.overarching-button-container no-center {
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.question-container, .question-block {
    display: none;
    position: relative;
}

.label-container {
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
}

.question-block.active {
    display: block;
}

.question-container {
    margin: 0 auto;
    padding: 2rem;
    min-height: 220px;
    background-color: rgb(255,255,255);
}

.question-container.active {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.question-block.alert-block, .question-block.user-progress-block {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
}

.question-container .label-container label {
    font-weight: 600;
}

.question-container label {
    font-size: 1.7rem;
    line-height: 1.8rem;
    margin-bottom: 2rem;
}

.question-block [type='radio'] {
    display: inline-block;
    position: static;
    opacity: 1;
    margin-right: 5px;
    margin-top: 7px;
}

.close-question-container, .close-alert-button {
    position: absolute;
    top: 18px;
    right: 18px;
    z-index: 0;
    font-size: 1.5rem;
    cursor: pointer;
}

.select-option {
    margin-bottom: 1rem;
}

.hyperlink-container {
    display: inline;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.question-container .btn-yellow-darktxt, .question-container .btn-yellow-whitetxt {
    font-weight: bold;
}

.income-service-container a:nth-child(2) {
    margin-left: 1rem;
}

.service-block a.btn-yellow-darktxt, .service-block a.btn-yellow-whitetxt {
    margin-top: 1rem;
}

.overarching-button-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.alert-block > .row {
    width: 100%;
}

.alert-block, .user-progress-block {
    background-color: #fff;
}

legend {
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 1.8rem;
    margin-bottom: 2rem;
    border-bottom: none;
    color: #000;
}

.overarching-button-container .alert-no-button, .overarching-button-container .progress-no-btn, .prev-hyper-btn {
    margin-right: 1rem;
}

.overarching-button-container .alert-yes-button, .overarching-button-container .progress-yes-btn, .next-hyper-btn {
    margin-left: 1rem;
}

@media only screen and (max-width:600px) {
    .progress-container {
        display: none !important;
    }
}

@media only screen and (min-width: 461px) {

    .overarching-button-container .prev-btn, .overarching-button-container .alert-no-button, .overarching-button-container .progress-no-btn, .prev-hyper-btn {
        margin-right: 1rem;
        flex-basis: auto;
    }

    .overarching-button-container .next-btn, .overarching-button-container .alert-yes-button, .overarching-button-container .progress-yes-btn, .next-hyper-btn {
        margin-left: 1rem;
        flex-basis: auto;
    }
}

@media only screen and (max-width: 1000px) {
    .question-container, .action-container {
        width: 85%;
    }

    .question-container label {
        font-size: 1.7rem;
        margin-bottom: 2rem;
    }

    .error-msg {
        font-size: 1rem;
        bottom: 0px;
        position: static;
        margin-top: -1em;
    }
}


@media only screen and (max-width: 460px) {
    .next-btn, .prev-btn, .overarching-button-container .alert-no-button, .overarching-button-container .progress-no-btn, .overarching-button-container .alert-yes-button, .overarching-button-container .progress-yes-btn, .prev-hyper-btn, .next-hyper-btn {
        flex-basis: 160px;
        margin-bottom: 1rem;
        margin-right: 0px;
        margin-left: 0px;
    }

    .question-container label {
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .question-container {
        width: 90%;
        min-height: 300px;
    }

    .action-container {
        width: 90%;
    }

    .income-service-container a:nth-child(2) {
        margin-left: 0rem;
    }
}

@media screen and (max-width: 330px) {
    .next-btn, .prev-btn, .overarching-button-container .alert-no-button, .overarching-button-container .progress-no-btn, .overarching-button-container .alert-yes-button, .overarching-button-container .progress-yes-btn, .prev-hyper-btn, .next-hyper-btn {
        flex-basis: 140px;
        margin-bottom: 1rem;
        margin-right: 0px;
        margin-left: 0px;
    }

    .question-container label {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    .question-container {
        width: 95%;
        padding: 2rem 1rem;
        min-height: 300px;
    }

    .action-container {
        width: 95%;
        padding: 2rem 1rem;
    }
}

@media screen and (max-width: 500px) {
    .order-1 {
        order: 2;
    }

    .order-2 {
        order: 1;
    }
}
